import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserService } from "api/services/usersService";
import { useApi } from "api/useApi";
import OnboardingForm, { OnboardForm } from "components/onboarding/OnboardingForm";
import WaitForApproval from "components/onboarding/WaitForApproval";
import Spinner from "components/spinner";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setOnBoardingStatus } from "store/features/userSlice";

const OnboardingPage = () => {
  const { submitOnboarding } = useUserService();
  const { isError } = useApi();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector((state) => state.currentUser);
  const countries = useAppSelector((state) => state.staticData.countries);
  const dispatch = useAppDispatch();

  const onFormSubmit = async (data: OnboardForm) => {
    setIsLoading(true);
    const onboardingResponse = await submitOnboarding(data);
    if (isError(onboardingResponse)) {
      errorToast("Din păcate nu am reușit să primim datele tale. Te rugăm să încerci mai târziu.");
      setIsLoading(false);
      return;
    }
    dispatch(setOnBoardingStatus("ValidationInProgress"));
    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="h-full w-full flex px-2 md:px-0 mb-2 lg:mb-10 justify-start md:justify-center items-start">
        {user.status === "ValidationInProgress" && (
          <WaitForApproval />
        )}
        {user.status === "Confirmed" && (
          <OnboardingForm countries={countries} onSubmit={onFormSubmit} />
        )}
      </div>
    </>);
}

export default withAuthenticationRequired(OnboardingPage);
