import { Auction } from "api/models/auction";
import { AuctionSummary } from "api/models/auctionSummary";
import { Page } from "api/models/page";
import { GetAuctionsQuery } from "api/requests/getAuctionsQuery";
import { useApi } from "api/useApi";

const useAuctionsService = () => {
  const { get, post } = useApi();

  const getAuctions = async (query: GetAuctionsQuery) => {
    return await get<Page<AuctionSummary>>("auctions", { 
      params: query
    });
  };

  const getAuctionById = async (id: number) => {
    return await get<Auction | null>(`auctions/${id}`);
  };

  const signAuctionTermsAndConditions = async (id: number) => {
    return await post<number>(`auctions/${id}/terms`);
  }

  return { getAuctions, getAuctionById, signAuctionTermsAndConditions };
};

export { useAuctionsService };
