
function SearchInputFilter(props: {
  label: string,
  searchValue: string,
  searchPlaceholder: string,
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDownSearch?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}) {
  const { label, searchValue, searchPlaceholder, onChangeSearch, onKeyDownSearch } = props;

  return (
    <>
      <div className="block py-2 px-4 text-sm text-gray-700">
        <input
          type="text"
          id={`dropdown-filter-search-${label}`}
          autoComplete="off"
          value={searchValue}
          onChange={onChangeSearch}
          onKeyDown={onKeyDownSearch}
          placeholder={searchPlaceholder}
          className="p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border border-brand-300 focus:ring-1 focus:ring-brand-300 focus:border-brand-300"
        />
      </div>
      <div className="my-1 h-px bg-gray-100"></div>
    </>
  );
}

export default SearchInputFilter;