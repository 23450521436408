import logo from "assets/img/logo.png";
import FooterContent from "components/footer";
import { Link, Outlet } from "react-router-dom";

export default function DefaultLayout() {

  return (
    <div className="min-h-screen flex flex-col w-full">
      <div className="flex my-10 justify-center items-center">
        <Link to="/">
          <img src={logo} className="h-8" alt="Licitas Logo" />
        </Link>
      </div>
      <main className="mx-1 md:mx-20 h-full mt-4 transition-all bg-lightPrimary relative flex flex-grow">
        <div className="flex-1">
          <Outlet />
        </div>
      </main>

      <FooterContent />
    </div>
  );
}
