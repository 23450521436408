import type { CustomFlowbiteTheme } from 'flowbite-react';

const customTheme: CustomFlowbiteTheme = {
  accordion: {
    title: {
      heading: 'w-full'
    }
  },
  avatar: {
    root: {
      color: {
        brand: 'ring-brand-500 dark:ring-brand-400'
      },
      img: {
        placeholder: '-bottom-1 h-auto w-auto text-gray-400'
      }
    }
  },
  badge: {
    root: {
      color: {
        brand: 'bg-brand-100 text-brand-800 dark:bg-brand-200 dark:text-brand-900 group-hover:bg-brand-200 dark:group-hover:bg-brand-300'
      }
    }
  },
  button: {
    color: {
      brand: 'text-white bg-brand-500 border border-transparent enabled:hover:bg-brand-700 focus:ring-4 focus:ring-brand-300 dark:bg-brand-600 dark:enabled:hover:bg-brand-700 dark:focus:ring-brand-800',
      light: 'text-gray-900 bg-white border border-gray-300 enabled:hover:bg-gray-100 focus:ring-4 focus:ring-brand-300 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700 dark:focus:ring-gray-700',
    }
  },
  checkbox: {
    root: {
      color: {
        brand: 'focus:ring-brand-500 dark:ring-offset-gray-800 dark:focus:ring-brand-500 text-brand-500'
      }
    }
  },
  tabs: {
    tablist: {
      styles: {
        underline: 'w-full -mb-px border-b border-gray-200 dark:border-gray-700'
      },
      tabitem: {
        base: 'flex items-center justify-center px-auto py-4 rounded-t-lg text-xs md:text-sm lg:text-base font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
        styles: {
          underline: {
            base: 'w-full rounded-t-lg flex',
            active: {
              on: 'text-brand-600 rounded-t-lg border-b-2 border-brand-600 active dark:text-brand-500 dark:border-brand-500'
            }
          }
        }
      }
    }
  },
  textInput: {
    field: {
      input: {
        colors: {
          gray: 'bg-gray-50 border-gray-300 text-gray-900 focus:border-brand-500 focus:ring-brand-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-brand-500 dark:focus:ring-brand-500',
          failure: 'border-red-500 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:focus:border-red-500 dark:focus:ring-red-500'
        }
      }
    }
  },
  navbar: {
    collapse: {
      list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-base md:font-medium'
    },
    link: {
      active: {
        on: 'bg-brand-700 text-white dark:text-white md:bg-transparent md:text-brand-700',
        off: "border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-brand-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
      }
    },
    root: {
      inner: {
        base: 'mx-auto w-full flex flex-wrap items-center justify-center'
      }
    }
  },
  select: {
    field: {
      select: {
        colors: {
          gray: 'border-gray-300 bg-gray-50 text-gray-900 focus:border-brand-500 focus:ring-brand-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-brand-500 dark:focus:ring-brand-500'
        }
      }
    }
  },
  sidebar: {
    root: {
      inner: 'h-full overflow-y-auto overflow-x-hidden no-scrollbar rounded bg-gray-50 py-4 px-3 dark:bg-gray-800'
    },
    item: {
      base: 'flex items-center justify-center rounded-lg p-2 text-sm md:text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
      active: 'font-bold text-brand-500 dark:bg-brand-700',
      icon: {
        active: 'text-brand-600 dark:text-brand-100'
      }
    }
  },
  timeline: {
    item: {
      point: {
        marker: {
          icon: {
            base: 'h-3 w-3 text-brand-500 dark:text-brand-300',
            wrapper: 'absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-brand-200 ring-8 ring-white dark:bg-brand-900 dark:ring-gray-900'
          }
        }
      }
    }
  },
  toggleSwitch: {
    toggle: {
      base: 'toggle-bg rounded-full border group-focus:ring-4 group-focus:ring-brand-500/25',
      checked: {
        color: {
          brand: 'border-brand-500 bg-brand-500'
        }
      }
    }
  },
  tooltip: {
    arrow: {
      style: {
        dark: 'bg-brand-600 dark:bg-brand-400'
      }
    },
    style: {
      dark: 'bg-brand-600 text-white dark:bg-brand-400'
    }
  }
};

export default customTheme;