import { configureStore } from "@reduxjs/toolkit";
import { currentUserReducer } from "store/features/userSlice";
import { currentStaticDataReducer } from "./features/staticDataSlice";

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    staticData: currentStaticDataReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
