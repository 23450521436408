import { LotStatus } from 'api/models/lotStatus';
import { LotsSortByOptions } from 'api/requests/getLotsQuery';
import { useSearchParams } from 'react-router-dom';
import { LotsQueryParams, SortDirection } from 'static-data/constants';

type LotsSearchParams = {
  [LotsQueryParams.Search]?: string,
  [LotsQueryParams.Category]?: number[],
  [LotsQueryParams.Country]?: string[],
  [LotsQueryParams.Status]?: LotStatus[],
  [LotsQueryParams.IsReserved]?: number,
  [LotsQueryParams.Page]?: number,
  [LotsQueryParams.SortBy]?: LotsSortByOptions,
  [LotsQueryParams.SortDirection]?: SortDirection
};

const useLotsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lotsSearchParams: LotsSearchParams = {
    search: searchParams.get(LotsQueryParams.Search),
    category: searchParams.getAll(LotsQueryParams.Category).map(c => +c),
    country: searchParams.getAll(LotsQueryParams.Country),
    status: searchParams.getAll(LotsQueryParams.Status) as LotStatus[],
    isReserved: searchParams.get(LotsQueryParams.IsReserved) ? +searchParams.get(LotsQueryParams.IsReserved) : undefined,
    page: searchParams.get(LotsQueryParams.Page) ? +searchParams.get(LotsQueryParams.Page) : 1,
    sortBy: searchParams.get(LotsQueryParams.SortBy) as LotsSortByOptions,
    sortDirection: searchParams.get(LotsQueryParams.SortDirection) as SortDirection
  };
  const encodedQueryString = searchParams.toString();

  const setSearch = (text?: string) => {
    const newParams = searchParams;
    if (text) {
      newParams.set(LotsQueryParams.Search, text);
    }
    else {
      if (newParams.has(LotsQueryParams.Search))
        newParams.delete(LotsQueryParams.Search);
    }
    setSearchParams(newParams);
  };

  const setCountry = (countries?: string[]) => {
    const newParams = searchParams;

    if (newParams.has(LotsQueryParams.Country))
      newParams.delete(LotsQueryParams.Country);

    if (countries && countries.length > 0) {
      countries.reduce((acc, current) => {
        acc.append(LotsQueryParams.Country, current);
        return acc;
      }, newParams)
    }
    setSearchParams(newParams);
  }

  const setCategory = (categories?: number[]) => {
    const newParams = searchParams;

    if (newParams.has(LotsQueryParams.Category))
      newParams.delete(LotsQueryParams.Category);

    if (categories && categories.length > 0) {
      categories.reduce((acc, current) => {
        acc.append(LotsQueryParams.Category, current.toString());
        return acc;
      }, newParams)
    }
    setSearchParams(newParams);
  }

  const setStatus = (statuses?: LotStatus[]) => {
    const newParams = searchParams;

    if (newParams.has(LotsQueryParams.Status))
      newParams.delete(LotsQueryParams.Status);

    if (statuses && statuses.length > 0) {
      statuses.reduce((acc, current) => {
        acc.append(LotsQueryParams.Status, current);
        return acc;
      }, newParams)
    }

    setSearchParams(newParams);
  }

  const setPage = (page?: number) => {
    const newParams = searchParams;
    if (page && page >= 1) {
      newParams.set(LotsQueryParams.Page, page.toString());
    }
    else {
      if (newParams.has(LotsQueryParams.Page))
        newParams.delete(LotsQueryParams.Page);
    }
    setSearchParams(newParams);
  };

  const setIsReserved = (isReserved?: boolean) => {
    const newParams = searchParams;
    if (isReserved !== undefined) {
      newParams.set(LotsQueryParams.IsReserved, (+isReserved).toString());
    }
    else {
      if (newParams.has(LotsQueryParams.IsReserved))
        newParams.delete(LotsQueryParams.IsReserved);
    }
    setSearchParams(newParams);
  };

  const setSorting = (sortBy?: LotsSortByOptions, sortDirection?: SortDirection) => {
    const newParams = searchParams;
    if (sortBy) {
      newParams.set(LotsQueryParams.SortBy, sortBy);
    }
    else {
      if (newParams.has(LotsQueryParams.SortBy))
        newParams.delete(LotsQueryParams.SortBy);
    }

    if (sortDirection) {
      newParams.set(LotsQueryParams.SortDirection, sortDirection);
    }
    else {
      if (newParams.has(LotsQueryParams.SortDirection))
        newParams.delete(LotsQueryParams.SortDirection);
    }
    setSearchParams(newParams);
  };

  return { params: lotsSearchParams, setSearch, setCountry, setCategory, setStatus, setIsReserved, setPage, setSorting, queryString: encodedQueryString };
};

export default useLotsSearchParams;