import { Invoice } from "api/models/invoice";
import { InvoiceLot } from "api/models/invoiceLot";
import axios from "axios";
import { Accordion } from "flowbite-react";
import { neatDateWithoutTime } from "formatters/date";
import JSZip from "jszip";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";

const InvoiceCard = (props: {
  invoice: Invoice;
}) => {
  const { invoice } = props;

  const onDownloadImagesZip = async (lot: InvoiceLot) => {
    const zip = new JSZip();

    for (let i = 0; i < lot.images.length; i++) {
      const image = await axios.get(lot.images[i].url, { responseType: 'blob' });
      zip.file(lot.images[i].url.split("/").pop(), image.data);
    }

    const zipData = await zip.generateAsync({
      type: "blob",
      streamFiles: true,
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(zipData);
    link.download = `${lot.name}.zip`;
    link.click();
  }

  return (
    <Accordion collapseAll>
      <Accordion.Panel>
        <Accordion.Title className="!bg-white !text-black">
          <div className="flex justify-between items-center pr-2">
            <div className="space-y-2">
              <h3 className="font-semibold text-base md:text-lg lg:text-xl ">{invoice.auctionName}</h3>
              <p className="text-sm md:text-base">Data cumpărării: {neatDateWithoutTime(invoice.auctionEndsAtUtc)}</p>
            </div>
            <div className="space-y-2">
              <a href={invoice.file.url} download className="flex items-center gap-2 cursor-pointer underline underline-offset-2 font-medium text-sm hover:text-brand-500">
                <HiOutlineDocumentArrowDown className="shrink-0" />
                <span>Factura</span>
              </a>
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content className="!bg-white pt-8">
          {invoice.lots.map((lot, index) => (
            <div key={index}>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    src={lot.images[0]?.url}
                    alt={lot.images[0]?.description}
                    className="shrink-0 object-fill md:object-cover hover:object-scale-down h-12 w-12 rounded-lg" />
                  <article className="space-y-2">
                    <p className="font-semibold text-sm md:text-base lg:text-lg ">{lot.name} - {lot.id}</p>
                    <p className="text-xs md:text-sm lg:text-base">{lot.country}, {lot.city}</p>
                  </article>
                </div>
                <p onClick={() => onDownloadImagesZip(lot)} className="flex items-center gap-2 cursor-pointer underline underline-offset-2 font-medium text-sm hover:text-brand-500">
                  <HiOutlineDocumentArrowDown className="shrink-0" />
                  <span>Imagini</span>
                </p>
              </div>
              <hr className="h-px my-8 bg-gray-200 border-0"></hr>
            </div>
          ))}
          <div></div>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};

export default InvoiceCard;
