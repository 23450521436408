import { useSearchParams } from 'react-router-dom';
import { AuctionsQueryParams } from 'static-data/constants';

type AuctionsSearchParams = {
  [AuctionsQueryParams.Category]?: number[],
  [AuctionsQueryParams.Country]?: string[],
  [AuctionsQueryParams.Page]?: number,
};

const useAuctionsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auctionsSearchParams: AuctionsSearchParams = {
    category: searchParams.getAll(AuctionsQueryParams.Category).map(c => +c),
    country: searchParams.getAll(AuctionsQueryParams.Country),
    page: searchParams.get(AuctionsQueryParams.Page) ? +searchParams.get(AuctionsQueryParams.Page) : 1,
  };
  const encodedQueryString = searchParams.toString();

  const setCountry = (countries?: string[]) => {
    const newParams = searchParams;

    if (newParams.has(AuctionsQueryParams.Country))
      newParams.delete(AuctionsQueryParams.Country);

    if (countries && countries.length > 0) {
      countries.reduce((acc, current) => {
        acc.append(AuctionsQueryParams.Country, current);
        return acc;
      }, newParams)
    }
    setSearchParams(newParams);
  }

  const setCategory = (categories?: number[]) => {
    const newParams = searchParams;

    if (newParams.has(AuctionsQueryParams.Category))
      newParams.delete(AuctionsQueryParams.Category);

    if (categories && categories.length > 0) {
      categories.reduce((acc, current) => {
        acc.append(AuctionsQueryParams.Category, current.toString());
        return acc;
      }, newParams)
    }
    setSearchParams(newParams);
  }

  const setPage = (page?: number) => {
    const newParams = searchParams;
    if (page && page >= 1) {
      newParams.set(AuctionsQueryParams.Page, page.toString());
    }
    else {
      if (newParams.has(AuctionsQueryParams.Page))
        newParams.delete(AuctionsQueryParams.Page);
    }
    setSearchParams(newParams);
  };

  return { params: auctionsSearchParams, setCountry, setCategory, setPage, queryString: encodedQueryString };
};

export default useAuctionsSearchParams;