import { Bounce, ToastOptions, toast } from 'react-toastify';

const useToast = () => {

  const toastOptions: ToastOptions = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce
  };

  const successToast = (message: string) => {
    toast.success(message, toastOptions);
  };

  const errorToast = (message: string) => {
    toast.error(message, toastOptions);
  };

  return { successToast, errorToast };
};

export { useToast };
