import { User } from "api/models/user";
import { UpdateUserCommand } from "api/requests/updateUserCommand";
import { useUserService } from "api/services/usersService";
import Spinner from "components/spinner";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { setUser } from "store/features/userSlice";
import { useAppDispatch } from "store/hooks";

export type PersonalDataForm = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

type Errors = Partial<Record<keyof PersonalDataForm, string>>;

export default function PersonalData({ user }: { user: User }) {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { updateUser } = useUserService();
  const { successToast } = useToast();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<PersonalDataForm>({
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.email,
    phoneNumber: user.phoneNumber
  });
  const [errors, setErrors] = useState<Errors>({});
  const phoneUtil = PhoneNumberUtil.getInstance();

  const handleValidation = (): boolean => {
    const formErrors: Errors = {
      firstName: validateFirstName(fields.firstName),
      lastName: validateLastName(fields.lastName),
      phoneNumber: validatePhoneNumber(fields.phoneNumber)
    };

    setErrors(formErrors);
    return Object.values(formErrors).every(err => !err);
  };

  const validateField = (fieldName: keyof PersonalDataForm, value: any) => {
    switch (fieldName) {
      case "firstName":
        return validateFirstName(value);
      case "lastName":
        return validateLastName(value);
      case "phoneNumber":
        return validatePhoneNumber(value);
    }
  };

  const validateFirstName = (firstName: string): string => {
    if (!firstName) {
      return "Prenumele trebuie completat";
    }
  };

  const validateLastName = (lastName: string): string => {
    if (!lastName) {
      return "Numele trebuie completat";
    }
  };

  const validatePhoneNumber = (phoneNumber: string): string => {
    const invalidPhoneError = "Numărul de telefon este invalid";
    if (!phoneNumber) {
      return "Numărul de telefon trebuie completat";
    }
    try {
      return !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber)) ? invalidPhoneError : "";
    } catch (error) {
      return invalidPhoneError;
    }
  };

  const handleChange = (field: keyof PersonalDataForm, value: string | boolean) => {
    setFields({ ...fields, [field]: value });
    setErrors({ ...errors, [field]: validateField(field, value) });

  };

  const submitForm = async () => {
    if (!handleValidation()) {
      return;
    }

    const updatedUser: UpdateUserCommand = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      phoneNumber: fields.phoneNumber,
      companyCountryCode: user.company.countryCode,
      companyPostalOrZipCode: user.company.postalOrZipCode
    };

    setIsDataLoading(true);
    await updateUser(user.id, updatedUser);
    setIsDataLoading(false);

    const newUser: User = {
      ...user,
      firstName: fields.firstName,
      lastName: fields.lastName,
      phoneNumber: fields.phoneNumber
    }
    dispatch(setUser(newUser));

    successToast("Datele personale au fost salvate cu succes!")
  };

  if (isDataLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-2 md:mx-5 space-y-4 md:space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="lastName" value="Nume *" />
          </div>
          <TextInput
            id="lastName"
            type="text"
            placeholder="Popescu"
            color={errors.lastName ? "failure" : undefined}
            onChange={(e) => handleChange("lastName", e.target.value)}
            value={fields.lastName}
            helperText={errors.lastName}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="firstName" value="Prenume *" />
          </div>
          <TextInput
            id="firstName"
            type="text"
            placeholder="Daniel"
            color={errors.firstName ? "failure" : undefined}
            onChange={(e) => handleChange("firstName", e.target.value)}
            value={fields.firstName}
            helperText={errors.firstName}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="emailAddress" value="Prenume *" />
          </div>
          <TextInput
            id="emailAddress"
            type="email"
            disabled
            value={fields.emailAddress}
          />
        </div>

        <div>
          <label htmlFor='phone-number' className="text-sm text-navy-900 font-medium">Telefon *</label>
          <div className={`mt-2 h-[42px] rounded-lg bg-gray-50 border ${errors.phoneNumber ? 'border-red-500' : ' border-gray-300'}`}>
            <PhoneInput
              style={{ height: '100%', backgroundColor: 'gray-50' }}
              inputProps={{ id: 'phone-number', className: `border-none bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-2 ${errors.phoneNumber ? 'placeholder-red-700 focus:border-red-500 focus:ring-red-500' : 'focus:border-brand-500 focus:ring-brand-500 '}` }}
              inputStyle={{ height: '100%', width: '100%', }}
              countrySelectorStyleProps={{ buttonClassName: `h-full pl-2.5 pr-1 !rounded-l-lg border-solid border-0 border-r ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'}` }}
              forceDialCode
              defaultCountry="ro"
              value={fields.phoneNumber}
              onChange={(phone) => handleChange("phoneNumber", phone)}
            />
          </div>
          {errors.phoneNumber && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phoneNumber}</p>}
        </div>

        <div className="mt-2">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptPrivacyPolicy"
              color="brand"
              checked
              disabled />
            <Label htmlFor="acceptPrivacyPolicy" className="flex" disabled>
              Ai acceptat politica de confidențialitate
            </Label>
          </div>
        </div>

        <div className="mt-2">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptGeneralConditions"
              color="brand"
              checked
              disabled />
            <Label htmlFor="acceptGeneralConditions" className="flex" disabled>
              Ai acceptat condițiile generale ale site-ului
            </Label>
          </div>
        </div>
      </div>

      <div className="mt-3 w-full md:w-[75%] flex justify-center items-center md:justify-start md:items-start">
        <Button
          className="w-[75%] md:w-[50%]"
          color="brand"
          size="lg"
          onClick={() => submitForm()}>
          Salvează modificările
        </Button>
      </div>
    </div>
  );
}
