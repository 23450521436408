import { Badge } from "flowbite-react";
import { HiX } from "react-icons/hi";

function AccordionFilterTitle(props: {
  titleLabel: string;
  selectedItems?: string;
  onClearFilter: () => void
}) {
  const { titleLabel, selectedItems, onClearFilter } = props;

  return (
    <div className="w-full flex justify-between text-black font-semibold pr-2">
      <p>{titleLabel}</p>
      <div className="flex justify-between items-center gap-2">
        <p>{selectedItems}</p>
        {selectedItems && <Badge color="brand" size="sm" icon={HiX} onClick={() => onClearFilter()} />}
      </div>
    </div>
  );
}

export default AccordionFilterTitle;