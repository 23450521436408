import { Popover } from "flowbite-react";
import { HiOutlineCheckCircle, HiOutlineExclamation } from "react-icons/hi";

function LotReservedPrice({
  isReserved,
  showLabel,
  labelClass,
  iconClass,
  popoverOnClick,
}: Readonly<{
  isReserved?: boolean,
  showLabel?: boolean,
  labelClass?: string,
  iconClass?: string,
  popoverOnClick?: boolean
}>) {

  const getIsReservedPopoverContent = (isReserved: boolean): JSX.Element => {
    return (
      <div className="w-64 text-sm text-gray-500 px-3 py-2">
        <p>
          {isReserved
            ? 'Lot cu rezervare. Vânzătorul trebuie să aprobe prețul dacă acesta este sub cel stabilit prin rezervare.'
            : 'Lot fără rezervare. Se atribuie celui mai mare ofertant.'}
        </p>
      </div>)
  };

  return (
    <Popover content={getIsReservedPopoverContent(isReserved)} trigger={popoverOnClick ? 'click' : 'hover'} placement="bottom">
      {isReserved
        ? <p className={`flex items-center underline decoration-dotted underline-offset-4 text-yellow-300 ${labelClass}`}>
          <HiOutlineExclamation className={`pr-1 ${iconClass}`} />{showLabel && 'Cu rezervare'}
        </p>
        : <p className={`flex items-center underline decoration-dotted underline-offset-4 text-green-500 ${labelClass}`}>
          <HiOutlineCheckCircle className={`pr-1 ${iconClass}`} />{showLabel && 'Fără rezervare'}
        </p>
      }
    </Popover>
  )
}
export default LotReservedPrice;
