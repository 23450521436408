import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
      authorizationParams={{
        scope: process.env.REACT_APP_AUTH_SCOPE,
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        redirect_uri: window.location.origin + process.env.REACT_APP_AUTH_CALLBCACK,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
