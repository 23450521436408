import axios from 'api/axios';
import { useAuth } from 'api/useAuth';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NetworkErrorHandler = ({ children }: { children?: JSX.Element | any[]; }) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {

        // The following code works fine in most cases, but there are some edge cases where it might not work as expected.
        // Keeping the code commented out for reference, but will come back to it in the future.

        // if (error.code === AxiosError.ERR_NETWORK) {
        //   navigate(`error/offline`);
        //   return;
        // }

        if (error.response?.status) {
          switch (error.response.status) {
            case 401:
              login();
              break;
            case 403:
              navigate(`error/unauthorized`);
              break;
            case 404:
              navigate(`error/not-found`);
              break;
          }
        }

        return error;
      });

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default NetworkErrorHandler;
