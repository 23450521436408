import { useAuth } from "api/useAuth";

const useAdminAuth = () => {
  const { isAuthenticated, user } = useAuth();

  const rolesPath = `${process.env.REACT_APP_AUTH_DOMAIN_NAMESPACE}/roles`;
  const adminRole = process.env.REACT_APP_AUTH_ADMIN_ROLE;

  const isAdmin = () => {
    return isAuthenticated && user[rolesPath].includes(adminRole);
  }

  return { isAdmin };
};

export default useAdminAuth;