import { Button, Checkbox, Label } from "flowbite-react";
import { FilterItem } from "./filterItem";

function CheckboxListFilter(props: {
  label: string,
  items: FilterItem[],
  onSelectItem: (item: any) => void,
  isChecked: (item: any) => boolean,
  onResetClick: () => void
}) {
  const { label, items, onSelectItem, isChecked, onResetClick } = props;

  return (
    <>
      <ul className="max-h-60 overflow-y-auto">
        {items.map((item) => (
          <li role="menuitem" key={`dropdown-item-${label}-${item.id}`} onClick={() => onSelectItem(item)}>
            <button className="flex items-center gap-2 flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 focus:outline-none" >
              <Checkbox id={`checkbox-${label}-${item.id}`}
                color="brand"
                className="cursor-pointer"
                onClick={(e) => { e.preventDefault(); e.stopPropagation();}}
                checked={isChecked(item)}
                onChange={(e) => { e.preventDefault(); e.stopPropagation(); onSelectItem(item); }}
              />
              <Label htmlFor={`checkbox-${label}-${item.id}`} className="cursor-pointer" onClick={(e) => { e.preventDefault(); e.stopPropagation(); onSelectItem(item); }}>{item.name}</Label>
            </button>
          </li>
        ))}
      </ul>
      <div className="my-1 h-px bg-gray-100"></div>
      <div className="flex justify-end mx-2">
        <Button color="brand" size="xs" onClick={() => onResetClick()} className="m-2 text-sm font-semibold">Șterge</Button>
      </div>
    </>
  );
}

export default CheckboxListFilter;