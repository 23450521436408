import { Country } from "api/models/country";
import CountrySelector from "components/countrySelector";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { Link } from "react-router-dom";
import { CountryCodes } from "static-data/constants";
import regexHelpers from "static-data/regex-helper.json";
import { isRomania } from "utils/countryHelper";
import companyFiscalCodeValidator from "utils/validators/companyFiscalCodeValidator";

export type OnboardForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  companyCountryCode: string;
  companyAddress: string;
  companyPostalOrZipCode: string;
  companyVatNumber: string;
  companyRegistrationNumber: string;
  acceptPrivacyPolicy: boolean;
  acceptGeneralConditions: boolean;
};

type Errors = Partial<Record<keyof OnboardForm, string>>;

export default function OnboardingForm({
  countries,
  onSubmit,
}: Readonly<{
  countries: Country[],
  onSubmit: (form: OnboardForm) => void;
}>) {
  const [fields, setFields] = useState<OnboardForm>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    companyName: "",
    companyCountryCode: CountryCodes.Romania,
    companyAddress: "",
    companyPostalOrZipCode: "",
    companyVatNumber: "",
    companyRegistrationNumber: "",
    acceptPrivacyPolicy: false,
    acceptGeneralConditions: false,
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();

  const handleValidation = (): boolean => {
    const formErrors: Errors = {
      firstName: validateFirstName(fields.firstName),
      lastName: validateLastName(fields.lastName),
      phoneNumber: validatePhoneNumber(fields.phoneNumber),
      companyName: validateCompanyName(fields.companyName),
      companyCountryCode: validateCompanyCountry(fields.companyCountryCode),
      companyAddress: validateCompanyAddress(fields.companyAddress),
      companyPostalOrZipCode: validateCompanyPostalOrZipCodes(fields.companyPostalOrZipCode),
      companyVatNumber: validateCompanyVatNumber(fields.companyVatNumber),
      companyRegistrationNumber: validateCompanyRegistrationNumber(fields.companyRegistrationNumber),
      acceptPrivacyPolicy: validateAcceptPrivacyPolicy(fields.acceptPrivacyPolicy),
      acceptGeneralConditions: validateAcceptGeneralConditions(fields.acceptGeneralConditions)
    };

    setErrors(formErrors);
    return Object.values(formErrors).every(err => !err);
  };

  const validateField = (fieldName: keyof OnboardForm, value: any) => {
    switch (fieldName) {
      case "firstName":
        return validateFirstName(value);
      case "lastName":
        return validateLastName(value);
      case "phoneNumber":
        return validatePhoneNumber(value);
      case "companyName":
        return validateCompanyName(value);
      case "companyCountryCode":
        return validateCompanyCountry(value);
      case "companyAddress":
        return validateCompanyAddress(value);
      case "companyPostalOrZipCode":
        return validateCompanyPostalOrZipCodes(value);
      case "companyVatNumber":
        return validateCompanyVatNumber(value);
      case "companyRegistrationNumber":
        return validateCompanyRegistrationNumber(value);
      case "acceptPrivacyPolicy":
        return validateAcceptPrivacyPolicy(value);
      case "acceptGeneralConditions":
        return validateAcceptGeneralConditions(value);
    }
  };

  const validateFirstName = (firstName: string): string => {
    if (!firstName) {
      return "Prenumele trebuie completat";
    }
  };

  const validateLastName = (lastName: string): string => {
    if (!lastName) {
      return "Numele trebuie completat";
    }
  };

  const validatePhoneNumber = (phoneNumber: string): string => {
    const invalidPhoneError = "Numărul de telefon este invalid";
    if (!phoneNumber) {
      return "Numărul de telefon trebuie completat";
    }
    try {
      return !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber)) ? invalidPhoneError : "";
    } catch (error) {
      return invalidPhoneError;
    }
  };

  const validateCompanyName = (companyName: string): string => {
    if (!companyName) {
      return "Numele companiei trebuie completat";
    }
  };

  const validateCompanyCountry = (companyCountryCode: string): string => {
    if (!companyCountryCode) {
      return "Țara companiei trebuie completată";
    }
  };

  const validateCompanyAddress = (companyAddress: string): string => {
    if (!companyAddress) {
      return "Adresa companiei trebuie completată";
    }
  };

  const validateCompanyPostalOrZipCodes = (companyPostalOrZipCode: string): string => {
    if (!companyPostalOrZipCode) {
      return "Codul poștal trebuie completat";
    } else if (
      isRomania(fields.companyCountryCode) &&
      !new RegExp(
        // eslint-disable-next-line no-useless-escape
        /^\d{6}$/
      ).test(companyPostalOrZipCode)
    ) {
      return "Codul poștal este invalid";
    }

  };

  const validateCompanyVatNumber = (companyVatNumber: string): string => {
    if (!companyVatNumber) {
      return "CIF-ul companiei trebuie completat";
    } else if ((isRomania(fields.companyCountryCode) &&
      !companyFiscalCodeValidator(companyVatNumber)) ||
      !new RegExp(regexHelpers.europeanVATNumbersRegex).test(companyVatNumber)) {
      return "CIF-ul este invalid";
    }
  };

  const validateCompanyRegistrationNumber = (companyRegistrationNumber: string): string => {
    if (!isRomania(fields.companyCountryCode)) {
      return;
    }
    if (!companyRegistrationNumber) {
      return "Numărul de înregistrare trebuie completat";
    } else if (!new RegExp(
      // eslint-disable-next-line no-useless-escape
      /(?:J|F|C)(?:0?[1-9]|[123][0-9]|40)\/\d{1,6}\/[12][0-9]{3}$/
    ).test(companyRegistrationNumber)
    ) {
      return "Numărul de înregistrare este invalid";
    }
  };

  const validateAcceptGeneralConditions = (acceptGeneralConditions: boolean): string => {
    if (!acceptGeneralConditions) {
      return "Trebuie să accepți condițiile generale";
    }
  };

  const validateAcceptPrivacyPolicy = (acceptPrivacyPolicy: boolean): string => {
    if (!acceptPrivacyPolicy) {
      return "Trebuie să accepți politica de confidențialitate";
    }
  };

  const handleChange = (field: keyof OnboardForm, value: string | boolean) => {
    setFields({ ...fields, [field]: value });

    if (isFormSubmitted) {
      setErrors({ ...errors, [field]: validateField(field, value) });
    }
  };

  const submitForm = () => {
    setIsFormSubmitted(true);

    if (!handleValidation()) {
      return;
    }

    onSubmit(fields);
  };

  return (
    <div className="w-full md:w-[75%]">
      <span className="font-bold text-navy-700 text-lg md:text-3xl ">Bun venit!</span>
      <p className="text-sm md:text-base pt-1 md:pt-2 text-gray-600">
        Pentru a putea începe să licitezi, avem nevoie de câteva informații
        din partea ta.
      </p>

      <div className="mt-3 md:mt-6 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">

        <div>
          <div className="mb-2 block">
            <Label htmlFor="lastName" value="Nume *" />
          </div>
          <TextInput
            id="lastName"
            type="text"
            placeholder="Popescu"
            color={errors.lastName ? "failure" : undefined}
            onChange={(e) => handleChange("lastName", e.target.value)}
            value={fields.lastName}
            helperText={errors.lastName}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="firstName" value="Prenume *" />
          </div>
          <TextInput
            id="firstName"
            type="text"
            placeholder="Daniel"
            color={errors.firstName ? "failure" : undefined}
            onChange={(e) => handleChange("firstName", e.target.value)}
            value={fields.firstName}
            helperText={errors.firstName}
          />
        </div>

        <div>
          <label htmlFor='phone-number' className="text-sm text-navy-900 font-medium">Telefon *</label>
          <div className={`mt-2 h-[42px] rounded-lg bg-gray-50 border ${errors.phoneNumber ? 'border-red-500' : ' border-gray-300'}`}>
            <PhoneInput
              style={{ height: '100%', backgroundColor: 'gray-50' }}
              inputProps={{ id: 'phone-number', className: `border-none bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-2 ${errors.phoneNumber ? 'placeholder-red-700 focus:border-red-500 focus:ring-red-500' : 'focus:border-brand-500 focus:ring-brand-500 '}` }}
              inputStyle={{ height: '100%', width: '100%', }}
              countrySelectorStyleProps={{ buttonClassName: `h-full pl-2.5 pr-1 !rounded-l-lg border-solid border-0 border-r ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'}` }}
              forceDialCode
              defaultCountry="ro"
              value={fields.phoneNumber}
              onChange={(phone) => handleChange("phoneNumber", phone)}
            />
          </div>
          {errors.phoneNumber && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phoneNumber}</p>}
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyName" value="Nume companie *" />
          </div>
          <TextInput
            id="companyName"
            type="text"
            placeholder="Licitas SRL"
            color={errors.companyName ? "failure" : undefined}
            onChange={(e) => handleChange("companyName", e.target.value)}
            value={fields.companyName}
            helperText={errors.companyName}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <CountrySelector
              label="Țara unde este înregistrată compania *"
              countries={countries}
              defaultCountry={fields.companyCountryCode}
              onCountryChange={(countryCode) => handleChange("companyCountryCode", countryCode)} />
          </div>
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyAddress" value="Adresă companie *" />
          </div>
          <TextInput
            id="companyAddress"
            type="text"
            color={errors.companyAddress ? "failure" : undefined}
            onChange={(e) => handleChange("companyAddress", e.target.value)}
            value={fields.companyAddress}
            helperText={errors.companyAddress}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyPostalOrZipCode" value="Cod poștal companie *" />
          </div>
          <TextInput
            id="companyPostalOrZipCode"
            type="text"
            placeholder="123456"
            color={errors.companyPostalOrZipCode ? "failure" : undefined}
            onChange={(e) => handleChange("companyPostalOrZipCode", e.target.value)}
            value={fields.companyPostalOrZipCode}
            helperText={errors.companyPostalOrZipCode}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="companyVatNumber" value="CIF (Cod de identificare fiscală) *" />
          </div>
          <TextInput
            id="companyVatNumber"
            type="text"
            placeholder="RO123456"
            color={errors.companyVatNumber ? "failure" : undefined}
            onChange={(e) => handleChange("companyVatNumber", e.target.value)}
            value={fields.companyVatNumber}
            helperText={errors.companyVatNumber}
          />
        </div>

        {isRomania(fields.companyCountryCode) &&

          <div>
            <div className="mb-2 block">
              <Label htmlFor="companyRegistrationNumber" value="Nr. înregistrare ONRC *" />
            </div>
            <TextInput
              id="companyRegistrationNumber"
              type="text"
              placeholder="J40/123/2021"
              color={errors.companyRegistrationNumber ? "failure" : undefined}
              onChange={(e) => handleChange("companyRegistrationNumber", e.target.value)}
              value={fields.companyRegistrationNumber}
              helperText={errors.companyRegistrationNumber}
            />
          </div>}
      </div>

      <div className="mt-3 md:mt-6 grid grid-cols-1 gap-4 lg:gap-6">
        <div className="col-start-1 mt-2">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptPrivacyPolicy"
              color={errors.acceptPrivacyPolicy !== undefined ? "red" : "brand"}
              onChange={(e) =>
                handleChange("acceptPrivacyPolicy", e.target.checked)} />
            <Label htmlFor="acceptPrivacyPolicy" className="flex">
              Sunt de acord cu &nbsp;
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={process.env.REACT_APP_ASSETS_LOCATION + 'PrivacyPolicy.pdf'}
                target="_blank">
                politica de confidențialitate
              </Link>
            </Label>
          </div>
          {errors.acceptPrivacyPolicy && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.acceptPrivacyPolicy}</p>}
        </div>

        <div className="col-start-1">
          <div className="flex items-center gap-2">
            <Checkbox id="acceptGeneralConditions"
              color={errors.acceptGeneralConditions !== undefined ? "red" : "brand"}
              onChange={(e) =>
                handleChange("acceptGeneralConditions", e.target.checked)} />
            <Label htmlFor="acceptGeneralConditions" className="flex">
              Sunt de acord cu &nbsp;
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={process.env.REACT_APP_ASSETS_LOCATION + 'GeneralConditions.pdf'}
                target="_blank">
                condițiile generale ale site-ului
              </Link>
            </Label>
          </div>
          {errors.acceptGeneralConditions && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.acceptGeneralConditions}</p>}
        </div>
      </div>

      <div className="flex justify-center items-center md:justify-start md:items-start">
        <Button
          className="mt-8"
          color="brand"
          size="xl"
          onClick={() => submitForm()}>
          Trimite informațiile spre aprobare
        </Button>
      </div>
    </div>
  );
}
