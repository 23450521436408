import { useAuth } from "api/useAuth";
import logo from "assets/img/logo.png";
import useAdminAuth from "auth/useAdminAuth";
import FooterContent from "components/footer";
import { Avatar, Banner, Button, Dropdown, Navbar } from 'flowbite-react';
import useLotsSearchParams from "hooks/lotsSearchParams";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { HiOutlineUser } from "react-icons/hi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineAccountCircle, MdOutlinePendingActions } from "react-icons/md";
import { RiAuctionFill } from "react-icons/ri";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { LotsQueryParams, ViewBreakpoints } from "static-data/constants";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

const MainLayout = () => {
  const { isAuthenticated, user, login, logout } = useAuth();
  const { isAdmin } = useAdminAuth();
  const storedUser = useAppSelector((state) => state.currentUser);
  const location = useLocation();
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.staticData.categories);
  const { slugify } = useSlug();
  const { setSearch } = useLotsSearchParams();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.md ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const activeRoute = (routeName: string) => {
    return decodeURI(location.pathname) === routeName;
  };

  const displayOnboardingBanner = storedUser &&
    (storedUser.status === "Created" || storedUser.status !== "Approved");

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value) {
      const searchTerm = event.currentTarget.value;
      setSearch(searchTerm);
      navigate(`/lots?${LotsQueryParams.Search}=${searchTerm}`)
      event.currentTarget.value = "";
    }
  }

  return (
    <div className="min-h-screen flex flex-col w-full justify-center items-center">

      {displayOnboardingBanner &&
        <Banner className="sticky z-40 top-0 w-full">
          <div className="flex w-full h-14 justify-center items-center bg-gradient-to-br from-brand-500 to-brand-secondary-500">
            <span className="font-medium text-white text-center text-sm md:text-base px-2">
              {storedUser.status === "Created"
                ? 'E nevoie să confirmi contul pentru a putea licita. Verifică-ți emailul!'
                : (() => {
                  switch (storedUser.status) {
                    case "Confirmed":
                      return <Link to="/onboarding">Vrei să licitezi? Imediat, mai întâi avem nevoie de câteva date de la tine →</Link>;
                    case "ValidationInProgress":
                      return 'Încă puțin și vei putea licita! Datele tale sunt analizate, revenim în cel mai scurt timp!';
                    case "Rejected":
                      return <a href="mailto:contact@licitas.ro">Ne pare rău, nu poți licita. Datele tale nu au trecut de analiză. Află mai multe aici →</a>
                    default: return '';
                  }
                })()
              }
            </span>
          </div>
        </Banner>
      }

      <div className={`sticky z-40 w-full bg-white ${displayOnboardingBanner ? 'top-14' : 'top-0'}`}>
        <Navbar fluid rounded className="w-full !px-0 !pb-2 md:!pb-0">
          <div className="flex w-full max-w-full xl:max-w-screen-2xl justify-between items-center px-1 md:px-4 md:pb-2.5">
            <div>
              <Navbar.Brand className="hidden md:block" as="div">
                <Link to="/">
                  <img src={logo} className="h-8" alt="Licitas Logo" />
                </Link>
              </Navbar.Brand>
            </div>
            <div className="flex items-center rounded-xl bg-lightPrimary text-navy-700 min-w-60 md:min-w-96 h-10">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400" />
              </p>
              <input
                name="search"
                autoComplete="off"
                type="text"
                placeholder="Caută..."
                onKeyDown={handleKeyDown}
                className="block h-full w-[100%] rounded-xl border-none bg-lightPrimary text-sm font-medium text-navy-700 focus:ring-0 outline-none placeholder:!text-gray-400"
              />
            </div>
            <div className="flex justify-between space-x-1 md:space-x-2 md:pr-0">
              {isAuthenticated ?
                <Dropdown
                  arrowIcon={false}
                  inline
                  placement="bottom"
                  label=""
                  renderTrigger={() =>
                    <Button color="brand" className="font-semibold">
                      <HiOutlineUser className={`h-5 w-5 ${!isSmallScreen && 'mr-2'}`} />
                      {!isSmallScreen && "Contul meu"}
                    </Button>
                  }>
                  {storedUser &&
                    <Dropdown.Header>
                      <div className="flex items-center space-x-2">
                        <Avatar alt="Utilizator" size="xs" img={isAuthenticated ? user.picture : ''} rounded />
                        {storedUser.firstName && storedUser.lastName && <span className="block text-sm">{storedUser.firstName} {storedUser.lastName}</span>}
                        <span className="block truncate text-sm font-medium">{storedUser.email}</span>
                      </div>
                    </Dropdown.Header>}
                  <Dropdown.Item icon={MdOutlineAccountCircle}
                    as={Link} to="my-account" className="text-sm text-gray-800">
                    Contul meu</Dropdown.Item>
                  {!isAdmin() && <Dropdown.Item icon={RiAuctionFill}
                    as={Link} to="my-account/bids" className="text-sm text-gray-800">
                    Licitările mele</Dropdown.Item>}
                  {!isAdmin() && <Dropdown.Item icon={LiaFileInvoiceDollarSolid}
                    as={Link} to="my-account/invoices" className="text-sm text-gray-800">
                    Facturi</Dropdown.Item>}
                  {isAdmin() && <Dropdown.Item icon={MdOutlinePendingActions}
                    as={Link} to="admin/pending-users" className="text-sm text-gray-800">
                    Cereri de înregistrare</Dropdown.Item>}
                  {<Dropdown.Divider />}
                  <Dropdown.Item className="text-left text-sm font-medium text-red-500 hover:text-red-500"
                    onClick={() => logout()}>
                    Ieși din cont</Dropdown.Item>
                </Dropdown>
                : <Button color="brand" className="font-semibold" onClick={() => login()}>
                  <HiOutlineUser className={`h-5 w-5 ${!isSmallScreen && 'mr-2'}`} />
                  {!isSmallScreen && "Intră în cont"}
                </Button>
              }
              <Navbar.Toggle className="mr-auto" />
            </div>
          </div>

          <div className="w-full md:h-0.5 md:bg-brand-500"></div>
          <Navbar.Collapse className="w-full max-w-full xl:max-w-screen-2xl md:h-12 md:flex md:justify-center md:items-center uppercase md:!pr-[40px]">
            <Navbar.Link className="md:hidden" to="/" as={Link} active={activeRoute("/")}>Prima pagină</Navbar.Link>
            <Navbar.Link to="/auctions" as={Link} active={activeRoute("/auctions")}>Licitații</Navbar.Link>
            <Navbar.Link to="/lots" as={Link} active={activeRoute("/lots")} >Loturi</Navbar.Link>
            <Dropdown
              label={<Navbar.Link className="uppercase">Categorii</Navbar.Link>}
              inline>
              {categories && categories.map((category) => (
                <Dropdown.Item key={category.id}
                  as={Link}
                  to={`/lots/${slugify(category.name, category.id)}`}
                  className="uppercase hover:text-brand-700">
                  {category.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <main className="w-full max-w-full xl:max-w-screen-2xl h-full transition-all bg-lightPrimary relative flex flex-grow px-3 md:px-4 py-4">
        <Outlet />
      </main>

      <FooterContent />
    </div>
  );
}

export default MainLayout;
