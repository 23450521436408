import { useEffect, useRef } from "react";
import { HiX } from "react-icons/hi";

function Drawer(props: {
  isOpen: boolean;
  extra?: string;
  onClose: () => void
  children?: JSX.Element | any[];
  [x: string]: any;
}) {
  const { isOpen, extra, onClose, children, ...rest } = props;
  const ref = useRef(null);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target)) {
        onClose();
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [ref, onClose]);

  return (
    <>
      {isOpen &&
        <div ref={ref} className={`fixed overflow-y-auto inset-0 z-50 max-h-screen p-4 transition-transform bg-gray-50 w-full ${isOpen ? "translate-x-0 " : "translate-x-full"} ${extra}`}
          {...rest}
        >
          <div className="h-10 flex justify-end items-center">
            <button onClick={onClose}
              className="text-gray-600 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-3xl font-semibold w-8 h-8 "><HiX /></button>
          </div>
          {children}
        </div>
      }
    </>
  );
}

export default Drawer;
