import { Outlet } from "react-router-dom";

const NoOuterLayout = () => {
  return (
    <div className="h-screen w-screen bg-gray-800">
      <main className="h-full w-full">
        <Outlet />
      </main>
    </div>
  );
}

export default NoOuterLayout;
