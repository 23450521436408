import { LogLevel } from "@microsoft/signalr";
import signalR from "use-signalr-hub";
import { AuctionStatusNotification } from "./messages/auctionStatusNotification";

const useAuctionsHub = (
  auctionIds: number[],
  onStatusChanged?: (notification: AuctionStatusNotification) => void
) => {
  const idsAsString = auctionIds.join(",");

  signalR.useHub(`${process.env.REACT_APP_API_BASE_URL}/push/auctions?ids=${idsAsString}`, {
    automaticReconnect: true,
    logging: LogLevel.None,
    onConnected: (hub) => {
      hub.on("StatusChanged", onStatusChanged);
    }
  })
}

export default useAuctionsHub;
