import { Invoice } from "api/models/invoice";
import { InvoiceType } from "api/models/invoiceType";
import { Page } from "api/models/page";
import { GetUserInvoicesQuery } from "api/requests/getUserInvoicesQuery";
import { useUserService } from "api/services/usersService";
import InvoiceCard from "components/card/InvoiceCard";
import Spinner from "components/spinner";
import { Pagination } from "flowbite-react";
import { useEffect, useState } from "react";

const InvoicesListView = ({
  type
}: {
  type?: InvoiceType
}) => {

  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
  const [invoices, setInvoices] = useState<Page<Invoice>>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const { getInvoices } = useUserService();

  const getUserInvoicesQuery: GetUserInvoicesQuery = {
    pageIndex: 0,
    pageSize: 10,
    type: type,
    includeStats: false,
    sortDescending: true
  };

  const fetchInvoices = async () => {
    setIsLoadingInvoices(true);

    const invoicesData = (await getInvoices(getUserInvoicesQuery)).data;
    setInvoices(invoicesData);

    setCurrentPage(getUserInvoicesQuery.pageIndex);
    setTotalPages(Math.trunc((invoicesData.totalCount + getUserInvoicesQuery.pageSize - 1) / getUserInvoicesQuery.pageSize));

    setIsLoadingInvoices(false);
  };

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const onPageChange = async (page: number) => {
    getUserInvoicesQuery.pageIndex = page - 1;

    await fetchInvoices();
  }

  if (isLoadingInvoices) {
    return <Spinner />;
  }

  return (
    <div>
      {invoices?.totalCount > 0
        ? <>
          <div className="flex flex-col space-y-2 mb-2">
            {invoices?.items.map((invoice, index) => (
              <InvoiceCard key={index} invoice={invoice} />
            ))}
          </div>

          {invoices && totalPages > 1 && <Pagination
            currentPage={currentPage + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            showIcons
            nextLabel="Înainte"
            previousLabel="Înapoi"
            className="flex justify-center md:float-right" />}
        </>
        : <h3 className="text-center align-middle text-base md:text-lg lg:text-xl">
          Nu există facturi în această categorie
        </h3>
      }
    </div>
  );
};

export default InvoicesListView;
