import { BiddedLot } from "api/models/biddedLot";
import { BiddedLotsOverview } from "api/models/biddedLotsOverview";
import { InvoicesPage } from "api/models/invoicesPage";
import { Page } from "api/models/page";
import { User } from "api/models/user";
import { GetUserBiddedLotsQuery } from "api/requests/getUserBiddedLotsQuery";
import { GetUserInvoicesQuery } from "api/requests/getUserInvoicesQuery";
import { GetUsersQuery } from "api/requests/getUsersQuery";
import { SubmitOnboardingCommand } from "api/requests/submitOnboardingCommand";
import { UpdateUserCommand } from "api/requests/updateUserCommand";
import { UpdateUserStatusCommand } from "api/requests/updateUserStatusCommand";
import { VerifyEmailCommand } from "api/requests/verifyEmailCommand";
import { useApi } from "api/useApi";

const useUserService = () => {
  const { get, post, put } = useApi();

  const getUsers = async (query: GetUsersQuery) => {
    return await get<Page<User>>("users", { params: query });
  };

  const getMyUser = async () => {
    return await get<User | null>("users/me");
  }

  const createMyUser = async () => {
    return await post<number>("users/me");
  }

  const updateUser = async (id: number, data: UpdateUserCommand) => {
    return await put<number>(`users/${id}`, data);
  }

  const submitOnboarding = async (data: SubmitOnboardingCommand) => {
    return await post<number>(`users/me/onboarding`, data);
  }

  const verifyEmail = async (data: VerifyEmailCommand) => {
    return await post<number>('users/confirm-email', data);
  }

  const setUserStatus = async (id: number, data: UpdateUserStatusCommand) => {
    return await post<number>(`users/${id}/user-status`, data);
  }

  const getBiddedLots = async (query: GetUserBiddedLotsQuery) => {
    return await get<Page<BiddedLot>>("users/me/bidded-lots", { params: query });
  }

  const getBiddedLotsOverview = async () => {
    return await get<BiddedLotsOverview>("users/me/bidded-lots/overview");
  }

  const getInvoices = async (query: GetUserInvoicesQuery) => {
    return await get<InvoicesPage>("users/me/invoices", { params: query });
  }

  return { getUsers, getMyUser, createMyUser, updateUser, submitOnboarding, verifyEmail, setUserStatus, getBiddedLots, getBiddedLotsOverview, getInvoices };
};

export { useUserService };
