type SpinnerProps = {
  isAppLoader?: boolean;
};

const Spinner = ({ isAppLoader = false }: Readonly<SpinnerProps>) => {
  return (
    <div className={`flex justify-center ${isAppLoader ? 'h-screen w-screen items-center' : 'mt-20'}`}>
      <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-gray-900"></div>
    </div>
  );
}

export default Spinner;
