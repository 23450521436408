import { Link } from "react-router-dom";
import { Button } from "flowbite-react";

export default function WaitForApproval() {
  return (
    <div className="w-full md:w-[50%]">
      <span className="font-bold text-navy-700 text-lg md:text-3xl ">Contul tău este în curs de aprobare</span>
      <p className="mb-9 mt-4 text-sm md:text-base text-gray-600">
        Am primit datele tale și urmează să le analizăm. Procesul durează în jur
        de 1-2 zile lucrătoare, în funcție de flux. Vom reveni către tine cu un
        e-mail în cel mai scurt timp posibil.
      </p>
      <p className="mb-9 text-sm md:text-base text-gray-600">
        Până atunci, poți incepe să îți alegi loturile favorite.
      </p>
      <Link to="/" className="flex justify-center items-center">
        <Button
          className="w-[75%] md:w-[50%]"
          color="brand"
          size="xl">
          Vezi licitații active
        </Button>
      </Link>
      <p className="mt-9 text-sm md:text-base text-gray-600">
        Dacă dorești să ne contactezi, suntem disponibili la adresa de e-mail{" "}
        <a className="decoration-600 dark:decoration-500 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-brand-500"
            href="mailto:contact@licitas.ro">contact@licitas.ro</a>
      </p>
    </div>
  );
}
