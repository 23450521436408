import MessageBoxWithBrandCard from "components/card/MessageBoxWithBrandCard";
import { useSearchParams } from "react-router-dom";

export default function AccountUnblockedConfirmationPage() {
  const [queryParameters] = useSearchParams();
  const success = queryParameters.get("success") === "true";

  if (success) {
    return (
      <MessageBoxWithBrandCard iconType="success" title="Cont deblocat" message="Contul tău a fost deblocat. Te poți autentifica din nou." />
    );
  }

  return (
    <MessageBoxWithBrandCard iconType="error" title="Contul nu a putut fi deblocat"
      message={`Deblocarea contului nu a reușit.\nTe rugăm să reiei procesul.`} />
  );
}
