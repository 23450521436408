import { LotSummary } from "api/models/lotSummary";
import { useAuth } from "api/useAuth";
import LotBidModal from "components/lot/LotBidModal";
import LotEndDate from "components/lot/LotEndDate";
import LotReservedPrice from "components/lot/LotReservedPrice";
import InvalidUserModal from "components/user/InvalidUserModal";
import { Button } from "flowbite-react";
import { shortDate } from "formatters/date";
import { useEffect, useState } from "react";
import { HiOutlineCursorArrowRays } from "react-icons/hi2";
import { RiAuctionLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { ViewBreakpoints } from "static-data/constants";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

const LotSummaryCard = ({ lot }: { lot: LotSummary }) => {
  const { isAuthenticated } = useAuth();
  const user = useAppSelector((state) => state.currentUser);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { slugify } = useSlug();
  const navigate = useNavigate();
  const [openBidModal, setOpenBidModal] = useState(false);
  const [openInvalidUserModal, setOpenInvalidUserModal] = useState(false);
  const [userAcceptedTermsAndConditions, setUserAcceptedTermsAndConditions] = useState<boolean>(lot.auction.currentUserAuctionTermsSignature !== null);

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.lg ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const getLotDisplayDate = (lot: LotSummary): JSX.Element => {
    switch (lot.status) {
      case "Inactive":
        return <div className="flex flex-col lg:flex-row items-start lg:items-center lg:gap-1 text-sm lg:text-base font-medium text-gray-500 italic">
          <p>Începe la</p>
          <p>{shortDate(lot.auction.startsAtUtc)}</p>
        </div>
      case "Active":
        return <div className="flex flex-col lg:flex-row items-start lg:items-center lg:gap-1 text-sm lg:text-base font-medium text-gray-500 italic">
          <LotEndDate endDate={lot.endsAtUtc} showLabel className="lg:text-base font-semibold text-red-500" />
        </div>
      default:
        return <p className="text-sm lg:text-base font-medium text-gray-400 italic">Închis</p>;
    }
  };

  const lotAdditionalInfo = (lot: LotSummary): JSX.Element => {
    return <>
      {
        (lot.status !== 'Active' && lot.status !== 'Inactive') ?
          <p className="text-sm lg:text-base font-medium text-gray-400 italic pl-2">Închis</p> :
          <div className="w-full flex flex-row justify-between items-center gap-2 pl-1 lg:pl-0">
            <div>{getLotDisplayDate(lot)}</div>
            <div>
              {lot.bidsCount > 0 && <p className="font-semibold text-sm basis-1/3">{lot.bidsCount} <span className="font-normal">{lot.bidsCount > 1 ? 'licitări' : 'licitare'}</span> </p>}
            </div>
            {(lot.priceDetails && lot.priceDetails.currentPrice) &&
              <div className="flex justify-between items-center gap-2 lg:gap-4">
                <div className="flex flex-col items-end">
                  <p className="text-base lg:text-xl font-bold">{lot.priceDetails.currentPrice} {lot.auction.currency}</p>
                  <LotReservedPrice isReserved={lot.isReserved} showLabel labelClass="text-sm font-medium" iconClass="text-lg" popoverOnClick={isSmallScreen} />
                </div>
                <Button
                  size={isSmallScreen ? 'sm' : 'lg'}
                  className={isSmallScreen && 'h-12 rounded-none rounded-br-lg'}
                  color="brand"
                  disabled={lot.status !== 'Active'}
                  onClick={onClickBid}>
                  {isSmallScreen ? <RiAuctionLine /> : 'Licitează'}</Button>
              </div>}
          </div>
      }
    </>;
  };

  const navigateToAuction = (event: React.MouseEvent<HTMLParagraphElement>, lot: LotSummary) => {
    event.preventDefault();
    navigate(`/auctions/${slugify(lot.auction.name, lot.auction.id)}`);
  }

  const onClickBid = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isAuthenticated || !user || user.status !== "Approved") {
      setOpenInvalidUserModal(true);
      return;
    }
    if (lot.status === 'Active' && user && user.status === 'Approved') {
      setOpenBidModal(true);
    }
  };

  return (
    <div>
      <Link to={`/lots/${slugify(lot.name, lot.id)}/details`}>
        <div className={`flex flex-row border border-gray-200 bg-white shadow-md max-w-full h-24 lg:h-48 ${isSmallScreen ? 'rounded-tl-lg rounded-tr-lg' : ' rounded-lg'}`}>
          <img
            src={lot.images[0]?.url}
            alt={lot.images[0]?.description}
            className={`shrink-0 object-cover h-auto w-28 md:w-48 lg:w-72 ${isSmallScreen ? 'rounded-tl-lg' : 'rounded-s-lg'}`} />
          <div className="flex h-full flex-col grow min-w-0 justify-evenly lg:justify-between gap-1 lg:gap-2 p-2 lg:p-4">
            <p onClick={(event) => navigateToAuction(event, lot)} className="flex items-center gap-1 cursor-pointer underline underline-offset-2 decoration-gray-600 italic text-xs md:text-sm lg:text-base tracking-tight text-gray-600">
              <HiOutlineCursorArrowRays className="shrink-0 text-brand-300" />
              <span className="truncate">{lot.auction.name}</span>
            </p>
            <article>
              <h3 className="font-semibold text-sm lg:text-lg lg:text-2xl font-bold tracking-tight text-gray-900 truncate">
                {lot.name}
              </h3>
              <p className="mt-2 text-xs lg:text-sm lg:text-base italic">{lot.country.name}, {lot.city}</p>
            </article>
            {!isSmallScreen && <div>
              {lotAdditionalInfo(lot)}
            </div>}
          </div>
        </div>
        {isSmallScreen &&
          <div className="w-full h-12 bg-gray-200 rounded-bl-lg rounded-br-lg" onClick={onClickBid}>
            {lotAdditionalInfo(lot)}
          </div>
        }
      </Link>
      {openBidModal && isAuthenticated &&
        <LotBidModal
          show={openBidModal}
          onCloseModal={() => setOpenBidModal(false)}
          lot={lot}
          userAcceptedTermsAndConditions={userAcceptedTermsAndConditions}
          onAcceptedTermsAndConditions={() => setUserAcceptedTermsAndConditions(true)} />}

      {openInvalidUserModal &&
        <InvalidUserModal
          show={openInvalidUserModal}
          onCloseModal={() => setOpenInvalidUserModal(false)}
          isAuthenticated={isAuthenticated}
          user={user}
        />}
    </div>
  );
};

export default LotSummaryCard;
